// TODO: Temporary solution
export const ISSUES_SLUG_ID_MAP = {
  dev: {
    'pravni-emise': 38,
    'uroky-qtest': 37,
    'q-perioda-400': 36,
    'mesicni-from-q1-25': 35,
    '100-monthly-2024': 34,
    'portiva-automotive-finance-sro-7-85-2024-2028': 6,
    'smsvysledky-ii-2025': 5,
    'dluhopis-rtl-czech-vi-2027': 4,
    'test-dokuments': 3,
    'csb-lva-12-p-a-protiinflacni': 2,
    '10-monthly-2024': 1,
    's-24-02': 40,
    'buffler-agro-and-foods-viii2029': 41,
    'jd-living-viii2028': 42,
  },
  production: {
    's-24-02': 1,
    'buffler-agro-and-foods-viii2029': 2,
    'jd-living-viii2028': 3,
  },
} as const
